export const generateSocialMediaPostPrompt = (articleDraft, keywords, toneOfVoice, complexity, language) => {
  return `
    You are a professional content writer tasked with creating a compelling social media post. Follow these instructions carefully:
    
    1. Post Structure:
       - Use the provided outline as a guide, but expand on each point.
       - Main Sections: Use H2 headings for main sections as in the draft.
       - Subsections: Use H3 headings for subsections where necessary.
       - Paragraphs: Each section should have multiple paragraphs. Aim for 2-3 paragraphs per main section.
       - Use bullet points and numbered lists where appropriate to improve readability.
    
    2. Content Depth:
       - Provide detailed explanations for each point in the outline.
       - Include relevant examples or anecdotes to support your arguments.
       - Address common questions or concerns your readers might have.
    
    3. Keyword Usage:
       - Incorporate the main keywords naturally throughout the social media post.
       - Ensure the primary keyword is in the H1 title and at least one H2 heading.
    
    4. Social Media Post Components:
       - Title (H1): Create an engaging title that includes the primary keyword.
       - Introduction (150-200 words): Hook the reader and provide a brief overview of what the post will cover.
       - Body: Develop each section fully, connecting ideas logically.
       - Conclusion (100-150 words): Summarize key points and provide a call-to-action.
    
    5. Writing Style:
       - Tone of Voice: ${toneOfVoice}
       - Complexity: Aim for a Fog Index of ${complexity}
       - Use a conversational tone appropriate for social media content.
    
    6. Engagement:
       - Ask rhetorical questions to engage the reader.
       - Use analogies or metaphors to explain complex ideas.
       - Include a mix of informational and entertaining content.
    
    Write the social media post now, using markdown formatting where necessary. Focus solely on producing the content without any additional comments or explanations.
  `;
};