const checkContentQuality = (article, keywords) => {
  // Extract text content from Editor.js blocks
  const extractTextFromBlocks = (blocks) => {
    return blocks.map(block => {
      switch (block.type) {
        case 'paragraph':
        case 'header':
          return block.data.text;
        case 'list':
          return block.data.items.join(' ');
        default:
          return '';
      }
    }).join(' ');
  };

    // Calculate scores for each metric
    const calculateScore = (value, ideal, max) => {
      return Math.min(Math.round((value / ideal) * max), max);
    };

  const articleContent = extractTextFromBlocks(article.article_body.blocks);
  const wordCount = articleContent.split(/\s+/).filter(word => word.length > 0).length;

  // Function to safely count occurrences
  const countOccurrences = (text, pattern) => {
    try {
      return (text.match(new RegExp(pattern, 'gi')) || []).length;
    } catch (e) {
      console.error(`Error counting occurrences of "${pattern}":`, e);
      return 0;
    }
  };

  // Keyword check and density
  const keywordCounts = keywords.reduce((acc, keyword) => {
    acc[keyword] = countOccurrences(articleContent, keyword);
    return acc;
  }, {});

  const primaryKeyword = keywords[0]; // Assuming the first keyword is primary
  const keywordDensity = (keywordCounts[primaryKeyword] / wordCount) * 100;

  // Title length
  const titleBlock = article.article_body.blocks.find(block => block.type === 'header' && block.data.level === 1);
  const titleLength = titleBlock ? titleBlock.data.text.length : 0;

  // Word count score (max 30 points)
  let wordCountScore;
  if (wordCount > 2500) wordCountScore = 30;
  else if (wordCount >= 2000) wordCountScore = 25;
  else if (wordCount >= 1500) wordCountScore = 20;
  else if (wordCount >= 1000) wordCountScore = 15;
  else if (wordCount >= 600) wordCountScore = 10;
  else wordCountScore = 5;

  // Headings count
  const headingsCounts = {
    H1: 0, H2: 0, H3: 0, H4: 0, H5: 0
  };
  article.article_body.blocks.forEach(block => {
    if (block.type === 'header') {
      headingsCounts[`H${block.data.level}`]++;
    }
  });


   // Keyword density score (max 20 points)
   const keywordDensityScore = keywordDensity >= 1 && keywordDensity <= 2 ? 20 : 
   keywordDensity > 0 && keywordDensity < 3 ? 10 : 0;

  
  // Title length score (max 20 points)
  const titleLengthScore = titleLength >= 50 && titleLength <= 60 ? 20 :
                           titleLength >= 40 && titleLength <= 70 ? 15 : 10;

  // Headings score (max 30 points)
  let headingsScore = 0;
  if (headingsCounts.H1 === 1) headingsScore += 10;
  if (headingsCounts.H2 > 0) headingsScore += 10;
  if (headingsCounts.H3 > 0) headingsScore += 5;
  if (headingsCounts.H4 > 0 || headingsCounts.H5 > 0) headingsScore += 5;

  // Calculate overall score
  const overallScore = wordCountScore + keywordDensityScore + titleLengthScore + headingsScore;

  const report = {
    quality_control_report: {
      "Overall Score": {
        score: overallScore,
        maxScore: 100,
        percentage: `${Math.round((overallScore / 100) * 100)}%`
      },
      "Word Count": {
        description: "Determine the total word count of the article.",
        count: wordCount,
        score: wordCountScore,
        maxScore: 30
      },
      "Keyword Check": {
        description: "Count occurrences of each keyword throughout the article.",
        counts: keywordCounts
      },
      "Keyword Density": {
        description: "Calculate keyword density for the primary keyword.",
        density: `${keywordDensity.toFixed(2)}%`,
        isIdeal: keywordDensity >= 1 && keywordDensity <= 2 ? "Yes" : "No",
        score: keywordDensityScore,
        maxScore: 20
      },
      "Title Length": {
        description: "Measure the character count of the title.",
        length: titleLength,
        isIdeal: titleLength >= 50 && titleLength <= 60 ? "Yes" : "No",
        score: titleLengthScore,
        maxScore: 20
      },
      "Headings Count": {
        description: "Count the number of headings used in the article.",
        counts: headingsCounts,
        isH1Correct: headingsCounts.H1 === 1 ? "Yes" : "No",
        score: headingsScore,
        maxScore: 30
      }
    }
  };

  return [report];
};

export default checkContentQuality;