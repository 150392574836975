import axios from 'axios';
import axiosRetry from 'axios-retry';
import React from 'react';
import ReactDOM from 'react-dom';
import Notification from './components/Notifications/Notification';

// API Endpoints
export const DIRECTUS_INSTANCE = 'https://panel.reislo.com';
export const DIRECTUS_DATASETS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/data_sets`;
export const DIRECTUS_FILES_ENDPOINT = `${DIRECTUS_INSTANCE}/files`;
export const DIRECTUS_PROJECTS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/projects`;
export const OPENAI_COMPLETIONS_INSTANCE = process.env.REACT_APP_OPENAI_COMPLETIONS_INSTANCE;
export const API_URL = process.env.REACT_APP_API_URL;

export const DIRECTUS_PERSONAS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/personas`;
export const DIRECTUS_ARTICLES_ENDPOINT = `${DIRECTUS_INSTANCE}/items/articles`;
export const DIRECTUS_HEATMAPS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/heatmaps`;
export const DIRECTUS_USER_JOURNEYS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/userjourney`;
export const DIRECTUS_ONBOARDING_ENDPOINT = `${DIRECTUS_INSTANCE}/items/onboarding_profiling`;
export const DIRECTUS_CHAT_SESSIONS_ENDPOINT = `${DIRECTUS_INSTANCE}/items/chat_sessions`;

// API Keys
export const REACT_APP_EMBEDINGS_API_KEY = process.env.REACT_APP_EMBEDINGS_API_KEY;
export const REACT_APP_GPT_API_KEY = process.env.REACT_APP_GPT_API_KEY;
export const ASSISTANT_ID = process.env.REACT_APP_ASSISTANT_ID;
export const ANTHROPIC_API_KEY = process.env.REACT_APP_ANTHROPIC_API_KEY;
export const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
export const PINECONE_API_KEY = process.env.REACT_APP_PINECONE_API_KEY;

// Pinecone Configuration
export const PINECONE_ENVIRONMENT = process.env.REACT_APP_PINECONE_ENVIRONMENT;
export const PINECONE_INDEX_NAME = process.env.REACT_APP_PINECONE_INDEX_NAME;

export const OPENAI_API_URL = 'https://api.openai.com/v1';
export const VECTOR_STORE_ID = process.env.REACT_APP_VECTOR_STORE_ID || 'vs_0aZJbV6SHHz6zKagR2osdkio';

// Create an Axios instance for Directus API
const directusAPI = axios.create({
  baseURL: DIRECTUS_INSTANCE,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Set up axios-retry
axiosRetry(directusAPI, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => error.response?.status === 429 || error.response?.status === 503,
});

// Add an interceptor to include the token in every request
directusAPI.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('directus_token') || sessionStorage.getItem('directus_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Create an Axios instance for OpenAI API
const openAIAPI = axios.create({
  baseURL: OPENAI_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${REACT_APP_GPT_API_KEY}`,
    'OpenAI-Beta': 'assistants=v2'
  }
});

// Generic function to handle errors from API requests
const handleApiError = (error) => {
  if (error.response) {
    console.error('Server responded with an error:', error.response.data);
    console.error('Status code:', error.response.status);
  } else if (error.request) {
    console.error('No response received:', error.request);
  } else {
    console.error('Error setting up request:', error.message);
  }
  throw error;
};

// Function to post data to OpenAI API
export const postCompletion = async (postData) => {
  try {
    return await openAIAPI.post('', postData);
  } catch (error) {
    return handleApiError(error);
  }
};

export const queryPinecone = async (vector, topK = 5) => {
  try {
    const response = await axios.post(`${API_URL}/query`, { vector, topK });
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const savePersonaData = async (dataToSave) => {
  try {
    const response = await directusAPI.post('/items/personas', dataToSave);
    console.log('Persona data saved successfully:', response.data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const saveProjectData = async (dataToSave) => {
  try {
    const response = await directusAPI.post('/items/projects', dataToSave);
    console.log('Project data saved successfully:', response.data);
    return response.data;
  } catch (error) {
    return handleApiError(error);
  }
};

export const createThread = async () => {
  try {
    const response = await openAIAPI.post('/threads');
    return response.data.id;
  } catch (error) {
    console.error('Error creating thread:', error);
    throw error;
  }
};

export const addMessageToThread = async (threadId, message) => {
  const response = await openAIAPI.post(`/threads/${threadId}/messages`, message);
  return response.data;
};

export const runAssistant = async (threadId, assistantId, customToolResources = {}) => {
  const vectorStoreId = await getVectorStoreId();
  
  const defaultToolResources = {
    file_search: {
      vector_store_ids: [vectorStoreId]
    }
  };

  const toolResources = {
    ...defaultToolResources,
    ...customToolResources
  };

  const response = await openAIAPI.post(`/threads/${threadId}/runs`, {
    assistant_id: assistantId,
    tool_resources: toolResources
  });
  return response.data.id;
};

export const checkRunStatus = async (threadId, runId) => {
  const response = await openAIAPI.get(`/threads/${threadId}/runs/${runId}`);
  return response.data;
};

export const listMessages = async (threadId) => {
  const response = await openAIAPI.get(`/threads/${threadId}/messages`);
  return response.data;
};

let notificationContainer = null;

const displayNotification = (assistantId, vectorStoreId) => {
  if (!notificationContainer) {
    notificationContainer = document.createElement('div');
    document.body.appendChild(notificationContainer);
  }

  ReactDOM.render(
    <Notification assistantId={assistantId} vectorStoreId={vectorStoreId} />,
    notificationContainer
  );
};

export const getAssistantId = async () => {
  try {
    const token = localStorage.getItem('directus_token') || sessionStorage.getItem('directus_token');
    const userId = localStorage.getItem('user_id');

    if (!token || !userId) {
      return ASSISTANT_ID;
    }

    const userResponse = await directusAPI.get(`/users/${userId}`, {
      params: { fields: ['linked_workspace'] }
    });

    const workspaceId = userResponse.data.data.linked_workspace;

    if (workspaceId) {
      const workspaceResponse = await directusAPI.get(`/items/workspace/${workspaceId}`, {
        params: { fields: ['assistant_id'] }
      });

      const workspaceAssistantId = workspaceResponse.data.data.assistant_id;

      if (workspaceAssistantId) {
        return workspaceAssistantId;
      }
    }

    return ASSISTANT_ID;
  } catch (error) {
    console.error('Error fetching assistant ID:', error);
    return ASSISTANT_ID;
  }
};

export const getVectorStoreId = async () => {
  try {
    const token = localStorage.getItem('directus_token') || sessionStorage.getItem('directus_token');
    const userId = localStorage.getItem('user_id');

    if (!token || !userId) {
      return VECTOR_STORE_ID;
    }

    const userResponse = await directusAPI.get(`/users/${userId}`, {
      params: { fields: ['linked_workspace'] }
    });

    const workspaceId = userResponse.data.data.linked_workspace;

    if (workspaceId) {
      const workspaceResponse = await directusAPI.get(`/items/workspace/${workspaceId}`, {
        params: { fields: ['vector_store_id'] }
      });

      const workspaceVectorStoreId = workspaceResponse.data.data.vector_store_id;

      if (workspaceVectorStoreId) {
        return workspaceVectorStoreId;
      }
    }

    return VECTOR_STORE_ID;
  } catch (error) {
    console.error('Error fetching vector store ID:', error);
    return VECTOR_STORE_ID;
  }
};

// Function to initialize and display the notification
export const initializeNotification = async () => {
  const assistantId = await getAssistantId();
  const vectorStoreId = await getVectorStoreId();
  displayNotification(assistantId, vectorStoreId);
};

// Call initializeNotification when the file is loaded
initializeNotification();

// Handle API errors globally
directusAPI.interceptors.response.use(response => response, handleApiError);

export { directusAPI };

export const ITEMS_PER_PAGE = 25;

export const fetchArticles = async (token, userId, currentPage) => {
  try {
    const response = await axios.get(`${DIRECTUS_ARTICLES_ENDPOINT}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        filter: { user_created: { id: { _eq: userId } } },
        limit: ITEMS_PER_PAGE,
        page: currentPage,
        sort: '-date_created',
        meta: 'total_count,filter_count',
        fields: '*,user_created.first_name,type'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching articles:', error);
    throw error;
  }
};

export const formatDate = (dateString) => {
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour24: false };
  return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
};

export const getChatSessions = async () => {
  try {
    const response = await directusAPI.get(DIRECTUS_CHAT_SESSIONS_ENDPOINT, {
      params: {
        sort: '-date_created',
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching chat sessions:', error);
    throw error;
  }
};

export const createChatSession = async (title, threadId, assistantId, vectorStoreId) => {
  try {
    const response = await directusAPI.post(DIRECTUS_CHAT_SESSIONS_ENDPOINT, {
      title,
      thread_id: threadId,
      assistant_id: assistantId,
      vector_store_id: vectorStoreId,
    });
    return response.data.data;
  } catch (error) {
    console.error('Error creating chat session:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    }
    throw error;
  }
};

export const updateChatSession = async (sessionId, data) => {
  try {
    const response = await directusAPI.patch(`${DIRECTUS_CHAT_SESSIONS_ENDPOINT}/${sessionId}`, data);
    return response.data.data;
  } catch (error) {
    console.error('Error updating chat session:', error);
    throw error;
  }
};

export const saveChatMessage = async (sessionId, messageData, threadId) => {
  try {
    const response = await directusAPI.post('/items/chat_messages', {
      session_id: sessionId,
      thread_id: threadId,
      role: messageData.role,
      content: messageData.content,
      timestamp: new Date().toISOString(),
      order: messageData.order
    });
    return response.data.data;
  } catch (error) {
    console.error('Error saving chat message:', error);
    throw error;
  }
};

export const fetchChatSessions = async (token, userId, page, sessionId = null) => {
  try {
    const params = {
      filter: { user_created: { _eq: userId } },
      sort: '-date_created',
      page: page,
      limit: ITEMS_PER_PAGE,
    };

    if (sessionId) {
      params.filter.id = { _eq: sessionId };
      params.fields = ['*', 'messages.*'];
    }

    const response = await directusAPI.get('/items/chat_sessions', { params });
    return response.data;
  } catch (error) {
    console.error('Error fetching chat sessions:', error);
    throw error;
  }
};

export const getChatMessages = async (sessionId) => {
  try {
    const response = await directusAPI.get('/items/chat_messages', {
      params: {
        filter: { session_id: { _eq: sessionId } },
        sort: 'order'
      }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching chat messages:', error);
    throw error;
  }
};