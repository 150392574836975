import { createThread, addMessageToThread, runAssistant, checkRunStatus, listMessages, getAssistantId } from '../../../api';

const generateStructurePrompt = (articleContext, keywords, toneOfVoice, complexity, language, contentType) => {
  let structureTemplate = '';

  switch (contentType.toLowerCase()) {
    case 'blogpost':
      structureTemplate = `
      {
        "sections": [
          {
        "header": "Use H2 at the top of each section. Include keywords to aid readability and SEO.",
          "paragraph": "Explain what should be in this section and add number of words to meet requirements"
          }
        ]
      }`;
      break;
    case 'social media post':
      structureTemplate = `
      {
        "sections": [
          {
            "header": "Attention-Grabbing Opening",
            "paragraph": "Start with a hook (20-30 words)"
          },
          {
            "header": "Main Message",
            "paragraph": "Deliver the core content (50-80 words)"
          },
          {
            "header": "Call-to-Action",
            "paragraph": "Encourage engagement (10-20 words)"
          }
        ]
      }`;
      break;
    case 'ecommerce product description':
      structureTemplate = `
      {
        "sections": [
          {
            "header": "Product Overview",
            "paragraph": "Brief introduction of the product (50-70 words)"
          },
          {
            "header": "Key Features",
            "paragraph": "List 3-5 main features (100-150 words)"
          },
          {
            "header": "Benefits",
            "paragraph": "Explain how the product solves problems or improves life (100-150 words)"
          },
          {
            "header": "Specifications",
            "paragraph": "Technical details or dimensions (50-100 words)"
          },
          {
            "header": "Call-to-Action",
            "paragraph": "Encourage purchase (20-30 words)"
          }
        ]
      }`;
      break;
    case 'product review':
      structureTemplate = `
      {
        "sections": [
          {
            "header": "Product Introduction",
            "paragraph": "Briefly introduce the product (100-150 words)"
          },
          {
            "header": "First Impressions",
            "paragraph": "Discuss initial thoughts and packaging (150-200 words)"
          },
          {
            "header": "Features and Performance",
            "paragraph": "Detailed analysis of product features and how it performs (300-400 words)"
          },
          {
            "header": "Pros and Cons",
            "paragraph": "List the advantages and disadvantages (150-200 words)"
          },
          {
            "header": "Comparison",
            "paragraph": "Compare with similar products if applicable (150-200 words)"
          },
          {
            "header": "Verdict",
            "paragraph": "Summarize and give final recommendation (100-150 words)"
          }
        ]
      }`;
      break;
    default:
      structureTemplate = `
      {
        "sections": [
          {
            "header": "Use H2 at the top of each section. Include keywords to aid readability and SEO.",
            "paragraph": "Explain what should be in this section and add number of words to meet requirements"
          }
        ]
      }`;
  }

  return ` Provide an content structure in PURE JSON format! Don't add your comment outside the {buckets} !!!: 
  ${structureTemplate}`;
};

const generateArticleStructure = async (articleContext, keywords, toneOfVoice, complexity, language, contentType) => {
  const isDev = process.env.NODE_ENV === 'development';
  
  try {
    if (!articleContext || articleContext.trim() === '') {
      throw new Error('Article context is empty. Please provide context for the article.');
    }

    const threadId = await createThread();
    isDev && console.log('Thread created with ID:', threadId);

    const messages = [
      {
        role: 'user',
        content: `
        You are the Copywriter. First, think about what information the reader of this article would be looking for. What sections should be included, and what is the most valuable way to organize the information? Article is top-of-tunnel content.
        Article Context: ${articleContext},
        Keywords: ${keywords.join(', ')} ,
        Tone of Voice: ${toneOfVoice},
        Complexity (Fog Index): ${complexity},
        Language: ${language},
        `
      },
      {
        role: 'assistant',
        content: 'I will ALWAYS do search through Files I have access to and create the article structure based on this information in the specified JSON format. If there is no information in the files, dont use fake data.'
      },
      {
        role: 'user',
        content: generateStructurePrompt(articleContext, keywords, toneOfVoice, complexity, language, contentType)
      }
    ];

    for (const message of messages) {
      await addMessageToThread(threadId, message);
      isDev && console.log('Message added to thread');
    }

    const assistantId = await getAssistantId();
    const runId = await runAssistant(threadId, assistantId);
    isDev && console.log('Assistant run started with ID:', runId);

    let runData;
    while (true) {
      runData = await checkRunStatus(threadId, runId);
      isDev && console.log('Run status:', runData.status);
      if (runData.status === 'completed') {
        break;
      } else if (runData.status === 'failed') {
        isDev && console.error('Run failed. Details:', runData);
        throw new Error(`Assistant run failed: ${runData.last_error?.message || 'Unknown error'}`);
      }
      await new Promise(resolve => setTimeout(resolve, 2000));
    }

    isDev && console.log('Run completed:', runData);
    const messagesRes = await listMessages(threadId);
    isDev && console.log('Messages in thread:', messagesRes);

    const assistantMessage = messagesRes.data.find(msg => msg.role === 'assistant');
    if (!assistantMessage || !assistantMessage.content || !assistantMessage.content.length) {
      throw new Error('No content generated by the assistant.');
    }

    const generatedText = assistantMessage.content[0].text.value;
    isDev && console.log('Generated text:', generatedText);

    // Check for file search usage
    const annotations = assistantMessage.content[0].annotations || [];
    const fileCitations = annotations.filter(annotation => annotation.file_citation);
    if (isDev) {
      if (fileCitations.length > 0) {
        console.log('File search used. Citations:', fileCitations);
      } else {
        console.log('File search not used in the response.');
      }
    }

    return { generatedText, threadId };

  } catch (error) {
    isDev && console.error('Error in generating article structure:', error);
    if (error.response) {
      isDev && console.error('API response data:', error.response.data);
    }
    throw new Error(`Failed to generate article structure: ${error.response ? error.response.data.error.message : error.message}`);
  }
};

export default generateArticleStructure;