import React from 'react';
import Chart from 'react-apexcharts';
import parseQualityReportToHTML from '../../QualityCheck/parseQualityReportToHTML';

const QualityCheck = ({ qualityReport, onCheckQuality, isCheckingQuality }) => {
  const overallScore = qualityReport && qualityReport[0]?.quality_control_report?.["Overall Score"];

  const chartOptions = {
    chart: {
      type: 'radialBar',
      offsetY: -20,
      sparkline: {
        enabled: true
      }
    },
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: '97%',
          margin: 5,
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: '14px',
            fontWeight: 600,
            color: '#888',
            offsetY: 0,
            fontWeight: 500,
          },
          value: {
            offsetY: -40,
            fontSize: '22px',
            fontWeight: 500,
            formatter: function (val) {
              return val + "%";
            }
          }
        }
      }
    },
    grid: {
      padding: {
        top: -10
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'horizontal',
        shadeIntensity: 0.5,
        gradientToColors: ['#4ADE80'],
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 100],
        colorStops: [
          {
            offset: 0,
            color: "#EF4444",
            opacity: 1
          },
          {
            offset: 50,
            color: "#FBBF24",
            opacity: 1
          },
          {
            offset: 100,
            color: "#4ADE80",
            opacity: 1
          }
        ]
      }
    },
    labels: ['Overall Score'],

  };

  const series = overallScore ? [parseInt(overallScore.percentage)] : [0];

  return (
    <div className="px-4 pt-2">

      {overallScore && (
        <div className="mb-6">
          <Chart options={chartOptions} series={series} type="radialBar" height={300} />
        </div>
      )}
      {qualityReport && Array.isArray(qualityReport) && qualityReport.length > 0 
        ? parseQualityReportToHTML(qualityReport)
        : <p>No quality report available. Click the button below to generate a report.</p>
      }
      <button
        onClick={onCheckQuality}
        className="bg-primary-500 text-white px-4 py-2 mt-4 w-full"
        disabled={isCheckingQuality}
      >
        {isCheckingQuality ? 'Loading...' : 'Check Content Quality'}
      </button>
    </div>
  );
};

export default QualityCheck;