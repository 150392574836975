import React, { useState, useEffect } from 'react';

const draftMessages = [
  "Checking draft article",
  "Analysing sources and files",
  "Combining all information together",
  "Making article draft",
  "Just last language improvements",
  "We're almost there",
  "Polishing the content",
  "Finalizing the structure",
  "Adding finishing touches",
  "Nearly complete"
];

const articleMessages = [
  "Generating article structure",
  "Researching relevant information",
  "Crafting engaging content",
  "Incorporating keywords naturally",
  "Refining language and tone",
  "Ensuring coherence and flow",
  "Adding depth to key points",
  "Polishing the final draft",
  "Performing final quality checks",
  "Preparing for publication"
];

const FullScreenLoader = ({ type }) => {
  const [messageIndex, setMessageIndex] = useState(0);
  const [fadeState, setFadeState] = useState('in');

  useEffect(() => {
    const messages = type === 'draft' ? draftMessages : articleMessages;
    const interval = setInterval(() => {
      setFadeState('out');
      setTimeout(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        setFadeState('in');
      }, 500); // Half of the interval for smooth transition
    }, 6000);

    return () => clearInterval(interval);
  }, [type]);

  const messages = type === 'draft' ? draftMessages : articleMessages;

  return (
    <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
      <div className="text-center">
        <div className={`rounded-full h-32 w-32 mx-auto mb-4 ${type === 'draft' ? 'animate-spin border-t-2 border-b-2 border-primary-500' : 'animate-spin border-t-2 border-b-2 border-primary-500'}`}></div>
        <p className="text-xl font-semibold mb-2">Generating {type === 'draft' ? 'Draft' : 'Article'}</p>
        <p className={`text-lg text-gray-600 transition-opacity duration-500 ${fadeState === 'in' ? 'opacity-100' : 'opacity-0'}`}>
          {messages[messageIndex]}
        </p>
      </div>
    </div>
  );
};

export default FullScreenLoader;